exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebotsanfrage-js": () => import("./../../../src/pages/angebotsanfrage.js" /* webpackChunkName: "component---src-pages-angebotsanfrage-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-leistungen-umzuege-js": () => import("./../../../src/pages/leistungen/umzuege.js" /* webpackChunkName: "component---src-pages-leistungen-umzuege-js" */),
  "slice---src-components-footer-jsx": () => import("./../../../src/components/footer.jsx" /* webpackChunkName: "slice---src-components-footer-jsx" */)
}

